// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-alustra-privacy-sheers-shades-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/products/alustra-privacy-sheers-shades.js" /* webpackChunkName: "component---src-pages-products-alustra-privacy-sheers-shades-js" */),
  "component---src-pages-products-bed-bath-kitchen-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/products/bed-bath-kitchen.js" /* webpackChunkName: "component---src-pages-products-bed-bath-kitchen-js" */),
  "component---src-pages-products-fabrics-roman-austrian-balloon-shades-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/products/fabrics-roman-austrian-balloon-shades.js" /* webpackChunkName: "component---src-pages-products-fabrics-roman-austrian-balloon-shades-js" */),
  "component---src-pages-products-hunter-douglas-horizontal-vertical-blinds-window-panels-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/products/hunter-douglas-horizontal-vertical-blinds-window-panels.js" /* webpackChunkName: "component---src-pages-products-hunter-douglas-horizontal-vertical-blinds-window-panels-js" */),
  "component---src-pages-products-patio-porch-shades-drapers-solar-screen-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/products/patio-porch-shades-drapers-solar-screen.js" /* webpackChunkName: "component---src-pages-products-patio-porch-shades-drapers-solar-screen-js" */),
  "component---src-pages-products-windows-cornices-draperies-valances-hardware-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/products/windows-cornices-draperies-valances-hardware.js" /* webpackChunkName: "component---src-pages-products-windows-cornices-draperies-valances-hardware-js" */),
  "component---src-pages-services-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-blind-shade-shutter-repair-window-covering-alternation-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/services/blind-shade-shutter-repair-window-covering-alternation.js" /* webpackChunkName: "component---src-pages-services-blind-shade-shutter-repair-window-covering-alternation-js" */),
  "component---src-pages-services-drapery-valance-design-furniture-upholstery-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/services/drapery-valance-design-furniture-upholstery.js" /* webpackChunkName: "component---src-pages-services-drapery-valance-design-furniture-upholstery-js" */),
  "component---src-pages-services-interior-design-consultation-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/services/interior-design-consultation.js" /* webpackChunkName: "component---src-pages-services-interior-design-consultation-js" */),
  "component---src-pages-services-service-information-free-estimates-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/services/service-information-free-estimates.js" /* webpackChunkName: "component---src-pages-services-service-information-free-estimates-js" */),
  "component---src-pages-services-window-covering-automation-installation-js": () => import("/home/polymath-ops/vsts-agent/_work/28/s/web/src/pages/services/window-covering-automation-installation.js" /* webpackChunkName: "component---src-pages-services-window-covering-automation-installation-js" */)
}

